<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'CompetitorCheckHistory' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>
    </div>

    <!-- If there's some data -->
    <div
      v-if="response"
      class="mt-6"
    >
      <v-card
        flat
        class="py-6 px-4"
      >
        <!-- Show the title and menu -->
        <div class="d-flex justify-space-between">
          <div
            class="pr-3 text-h4 font-weight-bold"
            :class="{ 'grey--text': !form.title }"
            @click="shouldShowEditDialog = true"
          >
            {{ form.title || "Untitled Report" }}
          </div>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isMakingRequest"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the retry button -->
              <v-list-item
                @click="shouldShowRetryDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Retry
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the edit button -->
              <v-list-item
                @click="shouldShowEditDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                @click="shouldShowDeleteDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the copy URL button -->
              <v-list-item
                @click="copyReportLink"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex justify-space-between align-end flex-wrap mt-6 mb-4 text-subtitle-2">
          <!-- Show the date -->
          <div>
            {{ formatDate(response.created_at) }}
          </div>

          <!-- Show the number of followers -->
          <v-chip
            color="primary"
            small
          >
            <v-icon small left>
              people
            </v-icon>

            {{ nFormatter(response.preview.followers) }} Followers
          </v-chip>
        </div>

        <!-- Show a divider -->
        <v-divider class="mt-4 mb-3"></v-divider>

        <!-- Show the report brief -->
        <div class="mt-3 d-flex flex-wrap align-center">
          <span class="text-subtitle-2">
            Time Duration:
          </span>
          &nbsp;
          <v-chip
            small
          >
            {{ response.month_duration }} {{ response.month_duration > 1 ? "months" : "month" }}
          </v-chip>
          &nbsp;&nbsp;

          <span class="text-subtitle-2">
            Search Keywords
          </span>
          &nbsp;&nbsp;
          <v-chip-group>
            <v-chip
              v-for="(query, index) in response.queries"
              :key="`query-${index}`"
              small
            >
              {{ query }}
            </v-chip>
          </v-chip-group>
        </div>

        <!-- Show the overview data -->
        <v-card
          v-if="response.posts_count > 1"
          class="box-shadow-soft rounded my-6"
        >
          <div
            v-if="response.overview_data"
            class="stats-container d-flex"
          >
            <!-- For each stat value -->
            <div
              v-for="(statValue, index) in statValues"
              :key="'stat-value-' + index"
              class="stat-item px-4 py-4"
              :style="{ 'width': `calc(100% / ${statValues.length})` }"
            >
              <div class="stat-item__title">
                {{ statValue.format ? nFormatter(statValue.value) : statValue.value }}
              </div>
              <div class="stat-item__text d-flex align-center pt-1 primary--text">
                <v-icon color="primary">
                  {{ statValue.icon }}
                </v-icon>
                &nbsp;
                {{ statValue.text }}
                &nbsp;
              </div>
            </div>
          </div>

          <!-- Show a graph for posts over time -->
          <div
            v-if="dayWiseSeries.length"
            class="pt-6"
          >
            <day-wise-graph
              :series="dayWiseSeries"
            />
          </div>
        </v-card>

        <!-- Show the report posts -->
        <v-row>
          <v-col
            v-for="post in posts"
            :key="post.id"
            cols="12"
            lg="6"
          >
            <post-item
              :post="post"
            >
              <template v-slot:caption>
                <text-highlight
                  :queries="response.queries"
                >
                  {{ post.caption }}
                </text-highlight>
              </template>
            </post-item>
          </v-col>
        </v-row>

        <!-- DOM element to be used to track scroll -->
        <div id="intersect-detector" ref="intersectDetector" v-intersect="handleScrollIntersect"></div>
      </v-card>
    </div>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete this report. This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the retry confirmation dialog -->
    <v-dialog
      v-model="shouldShowRetryDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> refresh </v-icon>

          Rerun this query?
        </v-card-title>

        <v-card-text class="pt-4">
          If succeeded, this will cost you another 1 credit!
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowRetryDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleRetrySubmit"
          >
            Retry
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="response">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model.trim="form.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-6"
            outlined
            dense
          ></v-text-field>

          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import Vue Component
import TextHighlight from 'vue-text-highlight'

// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import { maxLength } from "vuelidate/lib/validators"

// Import children components
const PostItem = () => import(/* webpackChunkName: "post-item" */ "@/blocks/common/PostItem")
const DayWiseGraph = () => import(/* webpackChunkName: "crm-day-wise" */ "@/components/crm/DayWise")

// Subscription ID for messageEvents
const subscriptionId = Symbol("CompetitorCheck/Report")

// Export the SFC
export default {
  // Name of the component
  name: "CompetitorCheckReport",

  // Register the components
  components: {
    PostItem,
    DayWiseGraph,

    TextHighlight
  },

  // Define local data variables
  data: () => ({
    // The loading state
    isLoading: false,
    // The report data
    response: null,
    // The posts data
    posts: [],
    postsPagination: 0,
    isFetchingPosts: false,

    // Whether or not we're loading
    isMakingRequest: false,
    shouldShowDeleteDialog: false,
    shouldShowRetryDialog: false,
    shouldShowEditDialog: false,

    // The form data
    form: {
      title: "",
      sharingAccess: "self"
    },

    // The computed daywise series
    dayWiseSeries: []
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the maximum number of pages possible
     *
     * @returns {number}
     */
    maxPagesPossible() {
      return Math.ceil(this.response.posts_count / 4)
    },

    /**
     * Get the total statistical values
     *
     * @returns {Array}
     */
    statValues() {
      return [
        {
          icon: "collections",
          text: "Posts",
          value: this.response.overview_data.posts,
          format: true
        },
        {
          icon: "thumb_up",
          text: "Likes",
          value: this.response.overview_data.likes,
          format: true
        },
        {
          icon: "play_circle",
          text: "Views",
          value: this.response.overview_data.views,
          format: true,
          hideIfZero: true
        },
        {
          icon: "chat_bubble",
          text: "Comments",
          value: this.response.overview_data.comments,
          format: true,
        },
        {
          icon: "send",
          text: "Shares",
          value: this.response.overview_data.shares,
          format: true,
          hideIfZero: true
        },
        {
          icon: "bookmark",
          text: "Saves",
          value: this.response.overview_data.saves,
          format: true,
          hideIfZero: true
        },
        {
          icon: "bar_chart",
          text: "Avg Eng",
          format: false,
          value: (this.response.overview_data.engagement_rate * 100).toFixed(2) + "%"
        }
      ]
      .filter((search) => search.hideIfZero && search.value === 0 ? false : true)
    },
  },

  // Define vuelidate validation rules
  validations: {
    form: {
      title: {
        maxLength: maxLength(100)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle the scroll event for the table rows
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
     handleScrollIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If there are no posts, return
      if (!this.posts.length) return

      // Otherwise
      this.fetchPosts()
    },

    /**
     * Fetch the posts for the report
     *
     * @returns {void}
     */
    async fetchPosts() {
      // Check if we're already fetching
      if (this.isFetchingPosts) return

      // If we've reached the maximum number of pages
      if (this.postsPagination >= this.maxPagesPossible) return

      // Mark as fetching and show a loader
      const loaderId = Symbol()
      this.isFetchingPosts = true
      this.$store.dispatch("loaders/add", loaderId)

      // Make the network request
      try {
        // Increment the page
        this.postsPagination++

        // Use the axios helper function
        const response = await axios({
          url: `/api/competitor-checks/${this.response.id}/posts`,
          params: {
            page: this.postsPagination,
          }
        })

        // Set the posts data
        this.posts = [
          ...this.posts,
          ...response.data.data
        ]
      }
      // Catch the error
      catch (error) {
        logger({ error, type: "CompetitorCheck/Report/fetchPosts" })
      }
      // Nonetheless
      finally {
        // Mark as not fetching and hide the loader
        this.isFetchingPosts = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Delete the report
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/competitor-checks/${this.response.id}`,
          method: "DELETE"
        })

        // If successful, redirect to the index
        this.$router.replace({ name: "CompetitorCheckHistory" })
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "CompetitorCheck/Delete Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Hide the dialog
      this.shouldShowDeleteDialog = false
    },

    /**
     * Handle the retry action
     *
     * @returns {void}
     */
    async handleRetrySubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/competitor-checks/${this.response.id}/retry`,
          method: "POST"
        })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Please wait, the report is refreshing..." })
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CompetitorCheck/Retry Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false

        // Hide the dialog
        this.shouldShowRetryDialog = false
      }
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("competitorCheck/updateItem", { ...this.response, ...this.form })

      // Reset the request state
      this.isMakingRequest = false
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Check if the triggerId does not match
      if (this.triggerId !== event.localData.triggerId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-competitor-check-xlsx-started":
          // Show a global loader
          this.downloadLoaderId = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaderId)

          break

        case "generate-competitor-check-xlsx-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break

        case "generate-competitor-check-xlsx-failed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink() {
      // Copy the link value
      const route = this.$router.resolve({ name: "CompetitorCheckReport", params: { uuid: this.response.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },

    /**
     * Compute series and annotations for the daywise graph
     *
     * @returns {void}
     */
    computeDaywiseData() {
      // If there are no posts
      if (this.response.posts_count === 0) {
        // Stop further execution
        return
      }

      // Get the date range for this report
      const startDate = dayjs(this.response.created_at).subtract(this.response.month_duration, "months")
      const endDate = dayjs(this.response.created_at)

      // The daywise posts count array
      const dayWisePosts = []

      // Go through each day between the start and end date, and add it to the dates array
      for (let date = startDate; date <= endDate; date = dayjs(date).add(1, "day")) {
        // Push it to the list
        dayWisePosts.push([
          date.unix() * 1000,
          this.response.daywise_data[date.format("YYYY-MM-DD")] || 0
        ])
      }

      // Append the values
      this.dayWiseSeries = [
        {
          name: "Posts",
          condition: true,
          data: dayWisePosts
        },
      ]
    }
  },

  /**
   * As soon as the data is ready
   *
   * @returns {void}
   */
  async created() {
    // Show the loaders
    const loaderId = Symbol("CompetitorCheckReport")
    this.$store.dispatch("loaders/add", loaderId)
    this.isLoading = true

    // Fetch the report data
    try {
      // Try making the request
      const response = await axios(`/api/competitor-checks/${this.$route.params.uuid}`)

      // Set the report data
      this.response = response.data

      // Set the form values
      this.form.title = this.response.title
      this.form.sharingAccess = this.response.sharing_access

      // Register a subscriber for messageEvents
      messageEvents.register({
        id: subscriptionId,
        module: "competitor-check",
        type: "all",
        key: "all",
        validator: (event) => event.module === "competitor-check" && event.localData.modelId === this.response.id,
        callback: this.handleMessageEvent
      })

      // Fetch the posts
      this.fetchPosts()

      // Compute the daywise data
      this.computeDaywiseData()
    } catch (error) {
      // log using the helper function
      logger({ type: "CompetitorCheck/created Fetch Report", error })

      this.$store.dispatch("toasts/add", { text: "An error occurred!" })
    } finally {
      // Hide the loaders
      this.isLoading = false
      this.$store.dispatch("loaders/remove", loaderId)
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
   beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.stats-container
  flex-wrap wrap
  border-bottom 2px solid #f4f6f8

  .stat-item
    flex-grow 1
    min-width 140px
    border-right 2px solid #f4f6f8

    &:last-child
      border-right none

    &__title
      font-size 1.5em
      font-weight bold
      line-height 1.5

    &__text
      font-size 0.85em
      font-weight 600

      .v-icon
        font-size 1.4em
</style>
